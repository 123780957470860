
/**
 * 2020.11.09 cary 编辑 智慧学堂首页
 */
<template>
  <div class="wisdom-school-box">
    <div class="block">
      <el-carousel height="518px" width="100%">
        <el-carousel-item v-for="item in 1" :key="item">
          <a href="https://news.yc.cnuen.net/#/languageonline?type=6" target="_blank">
            <img src="@/assets/images/school/banner.png" width="100%" height="100%" alt="" />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 同步课 -->
    <div class="course">
      <div class="course-header">
        <div class="type">同步课</div>
        <div class="all" @click="$router.push({path:`/school/one`,query:{pattern:0}})">
          查看全部
        </div>
      </div>
      <ul class="course-ul">
        <li v-for="(item, index) in lessonslist" :key="index">
          <div class="course_box_top flex-align-between">
            <div class="box_left">同步课</div>
          </div>
          <div class="img-box">
            <img
              :src="downloadURL + item.cover"
              :onerror="$store.state.course.defaultCourseCover[index % 5]"
              alt
            />
          </div>
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker">所属项目：双师课堂</div>
            <div class="course_speaker">主讲老师：{{item.teacherName}}</div>
            <div class="course_speaker course_time">
              上课时间：{{item.timeScope}}
            </div>
            <!-- <div class="course_speed">
              <el-progress
                :percentage="index"
                v-model="item.people"
              ></el-progress>
            </div> -->

            <div class="flex-align-between other-msg">
              <span class="flex-align other_left">暂无作业</span>
              <span class="button csp" @click="goPage(item.id,index)">查看课程</span>
            </div>
          </div>
        </li>
      </ul>
       <!-- 无课节-->
      <Empty :show="lessonslist && lessonslist.length === 0"></Empty>
    </div>
    <!-- 异步课 -->
    <div class="course">
      <div class="course-header">
        <div class="type">异步课</div>
        <div class="all" @click="$router.push({path:`/school/one`,query:{pattern:1}})">
          查看全部
        </div>
      </div>
      <ul class="course-ul">
        <li v-for="(item, index) in lessonsAsynclist" :key="index">
          <div class="course_box_top flex-align-between">
            <div class="box_left">异步课</div>
          </div>
          <div class="img-box">
            <img
              :src="downloadURL + item.cover"
              :onerror="$store.state.course.defaultCourseCover[index % 5]"
              alt
            />
          </div>
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker">所属项目：{{item.project.name}}</div>
            <div class="course_speaker">主讲老师：{{item.teacherName}}</div>
            <div class="course_speaker course_time">
              上课时间：{{item.timeScope}}
            </div>
            <!-- <div class="course_speed">
              <el-progress
                :percentage="index"
                v-model="item.people"
              ></el-progress>
            </div> -->

            <div class="flex-align-between other-msg">
              <span class="flex-align other_left">暂无作业</span>
              <span class="button csp" @click="goPage(item.id,index)">查看课程</span>
            </div>
          </div>
        </li>
      </ul>
       <!-- 无课节-->
      <Empty :show="lessonsAsynclist && lessonsAsynclist.length === 0"></Empty>
    </div>
    <!-- 公开课 -->
    <div class="course">
      <div class="course-header">
        <div class="type">公开课</div>
        <div class="all" @click="$router.push({path:`/school/one`,query:{pattern:2}})">
          查看全部
        </div>
      </div>
      <ul class="course-ul">
        <li v-for="(item, index) in lessonsPubliclist" :key="index">
          <div class="course_box_top flex-align-between">
            <div class="box_left">公开课</div>
          </div>
          <div class="img-box">
            <img
              :src="downloadURL + item.cover"
              :onerror="$store.state.course.defaultCourseCover[index% 5]"
              alt
            />
          </div>
          <div class="flex-column-between info">
            <h3 class="course_name wes-2">{{ item.name }}</h3>
            <div class="course_speaker">主讲老师：{{item.teacherName || '无'}}</div>
            <!-- <div class="course_speaker course_time">
              上课时间：{{item.timeScope}}
            </div> -->
            <!-- <div class="course_speed">
              <el-progress
                :percentage="index"
                v-model="item.people"
              ></el-progress>
            </div> -->

            <div class="flex-align-between other-msg">
              <span class="flex-align other_left"></span>
              <span class="button csp" @click="goPage(item.id,index)">查看课程</span>
            </div>
          </div>
        </li>
      </ul>
       <!-- 无课节-->
      <Empty :show="lessonsPubliclist && lessonsPubliclist.length === 0"></Empty>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // defualtImage:[ //默认图片
      //    'this.src="'+require("@/assets/images/course/course-cover01.png")+'"',
      //    'this.src="'+require("@/assets/images/course/course-cover02.jpg")+'"',
      //    'this.src="'+require("@/assets/images/course/course-cover03.jpg")+'"',
      //    'this.src="'+require("@/assets/images/course/course-cover04.jpg")+'"',
      //    'this.src="'+require("@/assets/images/course/course-cover05.jpg")+'"',
      // ],
      lessonsPubliclist:[], //公开课课节列表
      lessonsAsynclist:[], //异步课课节列表
      lessonslist: [], //同步课课节列表
      lessonsPublic:[],
      currentPage:1, //当前页
      pagesize: 3, //页码
      // lessonsPublictype:0,//公开课
      lessonsAsynctype:1,//异步课
      lessonstype:0,//同步课
    };
  },
  
  methods: {
    //获取同&异步课程列表
    async getCourseList(){
      let params = {
        page:String(this.currentPage),
        limit:String(this.pagesize),
        lessonsPattern:String(this.lessonstype),
      }
      let asyncparams = {
        page:String(this.currentPage),
        limit:String(this.pagesize),
        lessonsPattern:String(this.lessonsAsynctype),
      }
      let publicParams = {
          page:String(this.currentPage),
          limit:String(this.pagesize),
          // lessonsPattern:String(this.lessonsPublictype),
      }
      let resData = await this.$Api.Course.getCourseList(params) //同步
      let resDatas = await this.$Api.Course.getCourseList(asyncparams) //异步
      let resDatap = await this.$Api.Course.getPublicList(publicParams) //公开课
      // console.log(resDatap)
      this.lessonslist = resData.data.list;
      this.lessonsAsynclist = resDatas.data.list;
      this.lessonsPubliclist = resDatap.data.list;
      // console.log(this.lessonsPubliclist)
    },
    //跳转至课程详情页
    goPage(id,index){
      let path = '/course/'
      let _coverIndex = (index+1)%5
      this.$router.push( { 
        path: `/course/${id}`,
        query:{
          proid:this.currentProjectID,
          cover:_coverIndex,
          from:'all'
        }
      });
    },
  },
  mounted() {
    this.getCourseList()
  },
  activated() {
    // this.getCourseList()
  },
  
};
</script>

<style lang="less" scoped>
/*媒体查询*/
/*当页面大于1200px 时，大屏幕，主要是PC 端*/
@media (min-width: 1300px) {
  .course {
    width: 1200px;
    .course-ul {
      li {
        margin-right: 70px;
      }
    }
  }
}

/*在992 和1199 像素之间的屏幕里，中等屏幕，分辨率低的PC*/
@media (max-width: 1299px) {
  .course {
    width: 900px;
    .course-ul {
      li {
        margin-right: 35px;
      }
    }
  }
}
.wisdom-school-box {
  width: 100%;
  background: #fafafa;
  .block {
    width: 100%;
  }
  .course {
    margin: 90px auto 50px;
    background: #ffffff;
    .course-header {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 48px;
      border-bottom: 1px solid #f2f2f2;
      .type {
        font-size: 22px;
        color: #333333;
        font-weight: 500;
      }
      .all {
        cursor: pointer;
        font-size: 18px;
        color: #595959;
        font-weight: 500;
      }
    }
  }
  .course-ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 60px;
    padding-top: 43px;
    li {
      max-width: 308px;
      width: 30%;
      margin-bottom: 67px;
      box-sizing: border-box;
      padding: 20px 18px;
      background: #ffffff;
      box-shadow: 0px 5px 29px 0px rgba(22, 115, 255, 0.16);
      border-radius: 10px;
      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
    .course_box_top {
      box-sizing: border-box;
      padding-bottom: 10px;
      .box_left {
        border-left: 4px solid #2474ec;
        padding-left: 10px;
        color: #2474ec;
        font-weight: 400;
        font-size: 15px;
      }
      .icon_right {
        vertical-align: middle;
        display: inline-table;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        background-image: url("../../assets/images/home/icon_time.png");
        background-repeat: no-repeat;
      }
      .box_right {
        color: #ff1a1a;
        font-weight: 300;
        font-size: 14px;
      }
    }
    h3 {
      font-size: 18px;
      height: 50px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 12px;
    }
    .img-box {
      width: 100%;
      height: 167px;
      margin: 0 auto 10px;
      overflow: hidden;
    }

    img {
      max-width: 100%;
      display: block;
      border-radius: 3px;
      background-color: #1c4b90;
    }
    .info {
      min-height: 82px;
      //   .course_name {
      //   }
      .course_speaker {
        color: #595959;
        font-size: 15px;
        font-weight: 400;
        padding-bottom: 3px;
      }
      //   .course_time {
      //   }
      .course_speed {
        padding-top: 3px;
        padding-bottom: 10px;
      }
    }
    .other-msg {
      font-size: 12px;
      color: #999;
      .other_left {
        font-size: 15px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
      }
      .button {
        width: 119px;
        height: 31px;
        text-align: center;
        background: linear-gradient(90deg, #2272ec, #3a7bf6);
        box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
        border-radius: 5px;
        font-size: 14px;
        font-family: PingFang SC;
        line-height: 31px;
        color: #ffffff;
      }
      /*
    //   .time::before {
    //     content: " ";
    //     margin-right: 4px;
    //     width: 20px;
    //     height: 20px;
    //     background-image: url("../../../../static/news/time@2x.png");
    //     background-repeat: no-repeat;
    //   }*/
    }
  }
}
</style>